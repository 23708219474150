import { Grid, Stack } from '@mui/material';
import React from 'react';

const data = [
    { title: 'Ownership', description: 'You own the problem end-to-end and you solve it end-to-end' },
    { title: 'Integrity', description: 'Do the right thing and be honest as we believe that anything grey is black.' },
    { title: 'Humility', description: 'Take Pride with what you impacted but being humble.' },
    { title: 'Collaborate with Compassion', description: 'Empathisize with your fellow team members, customers and help them to succeed. Compassion goes a long way!' },
    { title: 'Walk the Talk', description: 'You are a doer. Your work is reflective, influential, shows leadership.' },
    { title: 'Efficient Engineering', description: 'You learn continuously and find ways to bring efficiency in everything you do.' },
];

export const Values = () => {
    return (
        <Stack sx={{ color:"#fff", background:"#4f46e5"}} padding={{xs:"30px",md:"60px 40px"}} marginTop="80px" marginBottom="20px">
            <p style={{ fontSize:"32px",textAlign: 'center', fontWeight: 600}}>
                Our values
            </p>
            <Grid container spacing={2} sx={{padding:"0px 40px"}} marginTop="40px">
                {data.map((item, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} sx={{textAlign:"center"}} marginBottom="40px">
                        <p style={{ fontSize:"20px",fontWeight:"500"}}>
                            {item.title}
                        </p>
                        <p style={{ fontSize:"16px",marginTop:"10px"}}>
                            {item.description}
                        </p>
                    </Grid>
                ))}
            </Grid>
        </Stack>
    );
};