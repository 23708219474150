import { CalendarIcon, LocationMarkerIcon, UsersIcon } from '@heroicons/react/solid';
import { Link } from 'gatsby';
import React, { useState } from 'react';
import positions from '../data/joblisting';
import { Stack } from '@mui/material';

export default function Example() {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [selectedType, setSelectedType] = useState('All');
  const [selectedLocation, setSelectedLocation] = useState('All');
  const [searchQuery, setSearchQuery] = useState('');

  // Get unique categories from positions
  const categories = ['All', ...new Set(positions.map((position) => position.category))];
  const types = ['All', ...new Set(positions.map((position) => position.type))];
  const locations = ['All', ...new Set(positions.map((position) => position.location))];

  // Apply filters and search query to positions
  const filteredPositions = positions.filter((position) => {
    const categoryCondition = selectedCategory === 'All' || position.category === selectedCategory;
    const typeCondition = selectedType === 'All' || position.type === selectedType;
    const locationCondition = selectedLocation === 'All' || position.location === selectedLocation;
    const searchCondition = position.title.toLowerCase().includes(searchQuery.toLowerCase());
    return categoryCondition && typeCondition && locationCondition && searchCondition;
  });

  // Get unique categories from filtered positions
  const uniqueCategories = [...new Set(filteredPositions.map((position) => position.category))];

  // Function to get the total number of open roles for a category
  function getOpenRolesCount(category) {
    const openRoles = filteredPositions.filter(
      (position) => position.category === category
    );
    return openRoles.length;
  }

  return (
    <Stack className="bg-white shadow overflow-hidden rounded-lg" sx={{ padding:{xs:'1rem',md:'1rem 5rem'} }}>
      <div className="flex items-center space-x-4 md:gap-4 justify-between ">
        <FilterDropdown
          label="Category"
          options={categories}
          selectedOption={selectedCategory}
          onChange={(option) => setSelectedCategory(option)}
        />
        <FilterDropdown
          label="Type"
          options={types}
          selectedOption={selectedType}
          onChange={(option) => setSelectedType(option)}
        />
        <FilterDropdown
          label="Location"
          options={locations}
          selectedOption={selectedLocation}
          onChange={(option) => setSelectedLocation(option)}
        />
      </div>
      <Stack sx={{ width: '100%', padding: '1rem 0px' }}>
        <input
          type="text"
          placeholder="Search Job Title"
          className="border-gray-400 border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded w-full p-2.5"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </Stack>
      {uniqueCategories.map((category) => (
        <div key={category}>
          <Stack sx={{width:"100%",justifyContent:"space-between",flexDirection:"row",alignItems:"center"}}>
            <h1 className="text-2xl font-medium mt-4 text-indigo-600">
            {category}
          </h1>
          <h1 className="text-xl font-medium mt-4 ">
            ({getOpenRolesCount(category)} open roles)
          </h1>
          </Stack>
          
          <ul className="divide-y divide-gray-200">
            {filteredPositions
              .filter((position) => position.category === category)
              .map((position) => (
                <li key={position.id}>
                  <Link to={`/careers/${position.slug}`} className="block hover:bg-gray-50">
                    <div className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        <p className="text-lg font-[600]  truncate">{position.title}</p>
                        <div className="ml-2 flex-shrink-0 flex">
                          <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {position.type}
                          </p>
                        </div>
                      </div>
                      <p style={{ overflow: 'hidden', textOverflow: 'ellipsis', display: ' -webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical', fontSize: '16px' }}>Job Description:<span dangerouslySetInnerHTML={{ __html: position.description }}></span></p>
                      <div className="mt-2 sm:flex sm:justify-between">
                        <div className="sm:flex">
                          <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 ">
                            <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                            {position.location}
                          </p>
                        </div>
                        {/* <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                          <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                          <p>
                            Closing on <time dateTime={position.closeDate}>{position.closeDateFull}</time>
                          </p>
                        </div> */}
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      ))}
    </Stack>
  );
}

function FilterDropdown({ label, options, selectedOption, onChange }) {
  return (
    <div style={{ width: '30%' }}>
      <label htmlFor={label} className="font-medium text-gray-700">
        {label}:
      </label>
      <select
        id={label}
        className="block mt-1 pl-3 pr-10 py-2 text-base border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded border w-[100%]"
        value={selectedOption}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
